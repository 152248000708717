import { mapGetters } from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SwfApiService from "@/core/services/api.service.swf";
import moment from "moment";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import ProductCard from "@/view/content/widgets/product-card/product-card";
import SwfOfferEditRow from "./swf-offer-edit-row";
import { VALIDATORS } from "@/common/validators";
import { formatUTCDate } from "@/common/date-format";
import ApiService from "@/core/services/api.service";
import WindowRedirectService from "@/core/services/window-redirect.service";
import { SWF_ORDER_OPTIONS, SWF_TYPE } from "./swf-enums";

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },
  name: "swf",
  data() {
    return {
      swfTypes: SWF_TYPE,
      valid: false,
      isSaving: false,
      swfId: null,
      swfApiInitiated: false,
      usersAreLoading: false,
      cities: [],
      citiesLoading: false,
      shops: [],
      shopsLoading: false,
      searchCity: "",
      products: [],
      productsLoading: false,
      swfEdit: null,
      shopAddItem: false,
      info: {
        type: null,
        created: null,
        summaryFromShops: null,
        summaryFromOoblee: null,
        voucherSum: null
      },
      newRowForm: this.resetRowForm(),
      form: this.setForm(),
      rules: VALIDATORS,
      today: moment(),
      inStockRule: item => [
        v => {
          if (v <= item.inStock) return true;

          return "Quantity should be less than the in stock value.";
        }
      ]
    };
  },
  async created() {
    await SwfApiService.init();
    this.swfApiInitiated = true;

    this.swfEdit = this.$route.params.swfEdit;

    let title =
      this.swfEdit != null
        ? "Shopping with friends, ID: " + this.swfEdit.id + ""
        : "Insert new shopping with friends";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
    this.getCities();
    this.getShops();

    if (this.swfEdit != null && this.swfEdit.id != null) {
      this.form = this.setForm(this.swfEdit);
      this.calcInfoValues();
    }
  },
  components: {
    ConfirmDialog,
    ProductCard,
    SwfOfferEditRow
  },
  methods: {
    setForm(data) {
      const form = {
        id: data?.id,
        cityId: data?.city?.id || null,
        startDate: data?.startDate || null,
        endDate: data?.endDate || null,
        itemIds: [],
        // freeItem: null,
        orderOptionEnum: data?.orderOptions || SWF_ORDER_OPTIONS,
        maxNumberOfMembersPerGroup: data?.maxNumberOfMembers || 0
      };

      /*      const exists = data?.shoppingItems.find(item => item.id === data?.freeItem?.id);
      if (data?.freeItem && !exists) {
        data.freeItem["isFreeItem"] = true;
        form.itemIds.push(data.freeItem);
      }*/

      if (data?.shoppingItems) {
        form.itemIds = [...form.itemIds, ...data.shoppingItems];
      }

      form.itemIds = form.itemIds.map(item => {
        const shop = data.shops.find(shopItem => shopItem.id == item.shopId);
        item.price = item.finalPrice;

        return {
          shop: shop,
          item: item,
          discountPercentage: data.discountPercentage,
          oobleeDiscountPercentage: data.oobleeDiscountPercentage,
          quantity: item.quantity,
          oobleeVoucher: item.oobleeVoucher,
          editMode: false
          //   isFreeItem: item.isFreeItem
        };
      });

      return form;
    },
    getCities() {
      // Items have already been loaded
      if (this.cities.length > 0 || this.citiesLoading) return;

      this.citiesLoading = true;
      ApiService.query("api/cities")
        .then(res => {
          this.cities = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.citiesLoading = false));
    },
    getShops() {
      // Items have already been loaded
      if (this.shops.length > 0 || this.shopsLoading) return;

      this.shopsLoading = true;
      ApiService.query("api/shops/dropdown-list")
        .then(res => {
          this.shops = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.shopsLoading = false));
    },
    getProducts(shop) {
      // Items have already been requested
      if (this.productsLoading || !shop?.id || shop?.products) return;
      this.productsLoading = true;
      ApiService.get(`api/shop-products/dropdown-list`, shop.id)
        .then(res => {
          /* save products in shop so there is no overlap */
          shop["products"] = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.productsLoading = false));
    },
    previousState() {
      if (WindowRedirectService.isWindowRedirected()) {
        const events = WindowRedirectService.getEvents(window);
        events.triggerClose();
      } else {
        this.$router.go(-1);
      }
    },
    async validateAndSave() {
      const hasItems = this.form?.itemIds?.length > 0;
      const isValid = this.$refs.form.validate();

      if (!hasItems) {
        const translate = this.$i18n.t("common.atLeastOneItem");
        this.$bvToast.toast("Error: " + translate, {
          solid: true,
          variant: "error",
          autoHideDelay: 1000
        });
      }

      if (isValid && hasItems) {
        this.saveForm(this.form);
      }
    },
    saveForm(form) {
      this.isSaving = true;
      this.loading = true;

      // const freeItem = form.itemIds.find(item => item.isFreeItem);
      // form.freeItem = this.formatObject(freeItem);
      form.itemIds = form.itemIds.map(item => this.formatObject(item));

      if (form.id != null) {
        form.startDate = new Date(form.startDate).getTime() / 1000;
        form.endDate = new Date(form.endDate).getTime() / 1000;
        delete form.shoppingItems;
        delete form.city;
        delete form.shop;
        delete form.orderOptions;
        delete form.maxNumberOfMembers;

        SwfApiService.post(`/admin-panel/edit`, form)
          .then(response => {
            this.$log.debug("Swf updated: " + response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            this.previousState();
          });
      } else {
        form.startDate = new Date(form.startDate).getTime() / 1000;
        form.endDate = new Date(form.endDate).getTime() / 1000;

        SwfApiService.post(`/admin-panel/create`, form)
          .then(response => {
            this.$log.debug("Swf created: " + response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            this.previousState();
          });
      }
    },
    formatObject(object) {
      return {
        itemId: object?.item?.id,
        discountPercentage: object?.discountPercentage,
        oobleeDiscountPercentage: object?.oobleeDiscountPercentage,
        quantity: object?.quantity,
        oobleeVoucher: object?.oobleeVoucher
      };
    },
    /**
     * When selecting first time set end date to 24hr
     */
    startDateChanged() {
      if (this.endDateFormatted) return;
      const date = moment(this.startDateFormatted)
        .add(1, "days")
        .format();
      this.endDateFormatted = date;
    },
    onShopChange(event) {
      this.getProducts(event);
    },
    onCreateRow(event) {
      const itemExists = this.form.itemIds.find(arrItem => arrItem?.item?.id === event.item?.id);
      if (itemExists) return;

      this.form.itemIds.push(event);

      /*    if (this.form.itemIds.length === 1) {
        event["isFreeItem"] = true;
      }*/

      //  const freeItenNextValueDiscount = event?.isFreeItem ? 0 : event.oobleeDiscountPercentage;
      const inheritedData = {
        shop: event.shop,
        discountPercentage: event.discountPercentage,
        oobleeDiscountPercentage: event.oobleeDiscountPercentage,
        oobleeVoucher: event.oobleeVoucher
      };

      this.$refs.form.resetValidation();
      this.newRowForm = this.resetRowForm(inheritedData);
      this.calcInfoValues();
      this.shopAddItem = false;
    },
    resetRowForm(inheritedData) {
      const obj = {
        shop: null,
        item: null,
        discountPercentage: 0,
        oobleeDiscountPercentage: 0,
        quantity: 1,
        oobleeVoucher: 0,
        editMode: false
        //  isFreeItem: false
      };

      if (inheritedData) {
        return {
          ...obj,
          ...inheritedData
        };
      }

      return obj;
    },
    calcInfoValues() {
      //TYPE
      const shops = this.form.itemIds.map(item => item.shop.id);
      const filteredShops = shops.filter(v => v !== shops[0]);
      const filterIsSingle = filteredShops?.length < 1;

      let type = null;
      if (shops?.length) {
        type = filterIsSingle ? SWF_TYPE.SINGLE : SWF_TYPE.MULTISHOP;
      }

      // vocuher sum
      let voucherSum = 0;
      let summaryFromShops = 0;
      let summaryFromOoblee = 0;

      this.form.itemIds.forEach(arrItem => {
        voucherSum += arrItem.oobleeVoucher || 0;

        const shopDiscount =
          this.getPercentage(arrItem.item.price, arrItem.discountPercentage) || 0;
        const oobleeDiscount =
          this.getPercentage(arrItem.item.price, arrItem.oobleeDiscountPercentage) || 0;

        summaryFromShops += +shopDiscount;
        summaryFromOoblee += +oobleeDiscount;
      });

      voucherSum = voucherSum.toFixed(2);
      summaryFromShops = summaryFromShops.toFixed(2);
      summaryFromOoblee = summaryFromOoblee.toFixed(2);

      //created
      let created;
      if (this.swfEdit?.createdDate) {
        created = formatUTCDate(this.swfEdit.createdDate, "yyyy-MM-DD");
      }

      const info = {
        type,
        voucherSum,
        created,
        summaryFromShops,
        summaryFromOoblee
      };

      const oldInfo = this.info || {};

      this.info = Object.assign({}, oldInfo, info);
    },
    getPercentage(price, percent) {
      if (!price) return;
      const value = (percent / 100) * price;
      return value.toFixed(2);
    },
    onRemoveRow(item) {
      this.form.itemIds = this.form.itemIds?.filter(val => val !== item) || [];
      this.calcInfoValues();
    },
    onEditRow(item) {
      this.form.itemIds.forEach(item => {
        item["editMode"] = false;
      });

      this.toggleRowEditForm(item, true);
      this.calcInfoValues();
    },
    onSaveRow(item) {
      this.toggleRowEditForm(item, false);
      this.$refs.form.resetValidation();
      this.calcInfoValues();
    },
    toggleRowEditForm(item, val = true) {
      item["editMode"] = val;
    },
    setAddItem(val) {
      this.shopAddItem = val;
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    ...mapGetters(["currentUser"]),
    startDateFormatted: {
      get() {
        return formatUTCDate(this.form.startDate);
      },
      set(value) {
        this.form.startDate = value;
      }
    },
    endDateFormatted: {
      get() {
        return formatUTCDate(this.form.endDate);
      },
      set(value) {
        this.form.endDate = value;
      }
    }
  }
};
