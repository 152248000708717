export default {
  props: {
    item: null,
    showTitle: true,
    showInStock: true
  },
  name: 'product-card',
  data() {
    return {
      inStockVisible: this.showInStock ?? true
    };
  }
};
