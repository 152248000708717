import { VALIDATORS } from "@/common/validators";
import ProductCard from "@/view/content/widgets/product-card/product-card";

export default {
  props: {
    form: null,
    isNew: false,
    shops: null,
    shopsLoading: false,
    products: null,
    productsLoading: false,
    minDisc: 1
  },
  name: "swf-offer-edit-row",
  data() {
    return {
      valid: false,
      rules: VALIDATORS,
      searchProducts: "",
      searchShops: "",
      localForm: this.form
    };
  },
  async created() {
    /**
     * If Shop already exists get the products
     */
    if (!this.isNew && this.localForm?.shop) {
      this.onShopChange(this.localForm.shop);
    }
  },
  components: {
    ProductCard
  },
  methods: {
    onShopChange(event) {
      this.$emit("onShopChange", event);
    },
    onSave() {
      // console.log("hello", this.localForm);
      if (!this.isValid()) {
        return;
      }
      if (this.localForm?.oobleeVoucher === 0 || !this.localForm?.oobleeVoucher) {
        this.$bvToast.toast(" ", {
          solid: true,
          title: `Voucher value must be greater than 0.`,
          variant: "warning",
          noCloseButton: false
        });
        return;
      }
      if (
        (this.localForm?.item.price.toFixed(2) -
          this.getPercentage(this.localForm.discountPercentage) <
          1 &&
          this.localForm?.item.price.toFixed(2) -
            this.getPercentage(this.localForm.discountPercentage) !==
            0) ||
        (this.localForm?.item.price.toFixed(2) -
          this.getPercentage(this.localForm.oobleeDiscountPercentage) <
          1 &&
          this.localForm?.item.price.toFixed(2) -
            this.getPercentage(this.localForm.oobleeDiscountPercentage) !==
            0)
      ) {
        this.$bvToast.toast(" ", {
          solid: true,
          title: `Price of product with discount shouldn't be less then 1 euro`,
          variant: "warning",
          noCloseButton: false
        });
        return;
      }

      this.$emit("onSave", this.localForm);
    },
    close() {
      this.$emit("close", true);
    },
    getPercentage(percent) {
      if (!this.localForm?.item?.price) return;
      const value = (percent / 100) * this.localForm.item.price;
      //this.minDisc =
      return value.toFixed(2);
    },

    isValid() {
      return (
        this.localForm &&
        this.localForm?.shop &&
        this.localForm?.item &&
        this.localForm?.quantity > 0
      );
    },
    getProductList() {
      if (this.localForm?.shop?.products) {
        return this.localForm.shop.products;
      }

      return null;
    }
  }
};
